import { ProductLine } from "@/lib/productLine";

export function useInAppTarget() {
  const targetDialogIFrameUrl = (
    productLine: ProductLine,
    targetId?: number
  ) => {
    const targetIdSuffix = targetId !== undefined ? `/${targetId}` : "";
    return `/inapps/target/${productLine.productId}/${productLine.id}${targetIdSuffix}`;
  };

  return { targetDialogIFrameUrl };
}
